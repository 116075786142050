.ape-wrapper[data-device-type="mweb"].ape-wrapper[data-page-type="ABsearch"], .ape-wrapper[data-device-type="mweb"].ape-wrapper[data-page-type="search"], .ape-wrapper[data-device-type="mshop"].ape-wrapper[data-page-type="ABsearch"], .ape-wrapper[data-device-type="mshop"].ape-wrapper[data-page-type="search"] {
  border: 1px solid #ccc;
  display: grid;
}

.ape-wrapper[data-device-type="mweb"].ape-wrapper[data-page-type="ABsearch"] .ape-feedback, .ape-wrapper[data-device-type="mweb"].ape-wrapper[data-page-type="search"] .ape-feedback, .ape-wrapper[data-device-type="mshop"].ape-wrapper[data-page-type="ABsearch"] .ape-feedback, .ape-wrapper[data-device-type="mshop"].ape-wrapper[data-page-type="search"] .ape-feedback {
  order: -1;
  width: 100%;
  text-align: left !important;
  height: 24px !important;
  margin-top: 0 !important;
  padding-left: 8px !important;
}

.ape-placement[data-slot-name="auto-left-advertising-2"], .ape-placement[data-slot-name="fresh-ad-left-2"], .ape-placement[data-slot-name="wfm-ad-left-2"] {
  display: none;
}

.ape-placement[data-slot-name="auto-left-advertising-2"].show, .ape-placement[data-slot-name="fresh-ad-left-2"].show, .ape-placement[data-slot-name="wfm-ad-left-2"].show {
  display: block;
}

.ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mweb"] .ape-placement, .ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mshop"] .ape-placement {
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  overflow: hidden;
  height: inherit !important;
  max-width: 100% !important;
}

.ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mweb"] .ape-placement .border-enforcement, .ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mshop"] .ape-placement .border-enforcement {
  display: none !important;
}

.ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mweb"] .ape-placement img.ad-background-image, .ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mshop"] .ape-placement img.ad-background-image {
  transform: scaleX(1.01)scaleY(1.07)translate(-49.5%) !important;
}
/*# sourceMappingURL=apePlacements-1.50.bbd711fb.css.map */
